.asset-menu-items {
  position: absolute;
  border-radius: 0.8rem;
  outline: none;
  z-index: 100;
  background: var(--settingPopupBackgroundColor);

  /* --shadow-color: 0px 7px 30px 10px rgba(var(--backgroundColor), 0.8);
  box-shadow: var(--shadow-color);
  -webkit-box-shadow: var(--shadow-color);
  -moz-box-shadow: var(--shadow-color); */
}

.center-both {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropdown-arrow-container {
  margin: 0 0 0 0.5rem;
}

.dropdown-arrow {
  cursor: pointer;
  color: var(--textColor6);
  font-size: 1.6rem;
}
.dropdown-arrow:hover {
  opacity: 1;
}

.asset-item {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: var(--textColor7);
  text-decoration: none;
  padding: 0.85rem 0.8rem;
  font-weight: 500;
  img {
    width: 20px;
  }
}

.asset-item p {
  margin: 0;
  margin-left: 0.5rem;
}
