.input-form {
  max-width: 38.75rem;
}

.input-row {
  margin-bottom: 1.5rem;
}

.input-label {
  display: block;
  margin-bottom: 0.8rem;
  font-size: 1.5rem;
}

.text-input {
  border: 1px solid var(--inputColor);
  font-size: 1.5rem;
  width: 100%;
  color: white;
  box-sizing: border-box;
}
