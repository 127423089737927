.Radio-option {
  display: inline-block;
  margin-right: 3.1rem;
  cursor: pointer;
  color: #6b40ed;
}

.Radio-option-bubble {
  display: inline-block;
  width: 1.1rem;
  height: 1.1rem;
  border-radius: 1.5rem;
  vertical-align: middle;
  margin-right: 0.62rem;
  margin-bottom: 0.31rem;
  border: 2px solid #6b40ed;
}

.Radio-option-bubble-inner {
  display: none;
  background: #ff369a;
  width: 0.465rem;
  height: 0.465rem;
  border-radius: 1.5rem;
  margin-left: 0.31rem;
  margin-top: 0.31rem;
}

.Radio-option.active {
  color: #ff369a;
}

.Radio-option.active .Radio-option-bubble {
  border-color: #ff369a;
}

.Radio-option.active .Radio-option-bubble-inner {
  display: block;
}
