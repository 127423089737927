a.btn {
  text-decoration: none;
  display: inline-flex;
  align-items: center;
}
.btn-center {
  justify-content: center;
}
.btn-left {
  justify-content: start;
}
.btn {
  display: inline-flex;
  align-items: center;
  border: none;
  color: white;
  font-size: 1.4rem;
  border-radius: var(--border-radius-sm);
  cursor: pointer;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.btn-sm {
  padding: 0.5rem 1.4rem;
}

.btn-lg,
.btn-xl {
  padding: 0.8rem 1.5rem;
}

.btn-sm .btn-label {
  margin-left: 0.8rem;
}
.btn-lg .btn-label {
  margin-left: 1.1625rem;
  font-size: 1.85rem;
  font-weight: normal;
  line-height: 2.325rem;
  letter-spacing: 0;
}
.btn-xl .btn-label {
  margin-left: 1.1625rem;
  font-size: 1.85rem;
  font-weight: normal;
  line-height: 2.325rem;
  letter-spacing: 0;
}

.btn-sm .btn-image {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 2.4rem;
  height: 2.4rem;
}
.btn-lg .btn-image {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 2.3rem;
  height: 2.3rem;
}
.btn-xl .btn-image {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 2.7rem;
  height: 2.7rem;
}

.btn-primary {
  background: transparent;
  border-radius: var(--border-radius-sm);
}

.btn-primary:hover {
  /* background: var(--dark-blue-hover); */
}
.btn-primary:active {
  /* background: var(--dark-blue-active); */
}
.connect-wallet {
  background: var(--secondaryButtonBackground);
  border-radius: 0.5rem;
  min-height: 3.6rem;
  display: flex;
  /* border-radius: 8px;
background: var(--second-button-light-bg, #F3F3F3); */
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.10);
}
.connect-wallet > *:not(svg) {
  color: var(--secondaryButtonTextColor);
}
.connect-wallet span {
  font-weight: 700 !important;
}
.connect-wallet:hover {
  /* background-color: red; */
}
.connect-wallet-divider {
  width: 1.5px;
  min-height: 3.6rem;
  background: var(--connectWalletDividerColor);
}
.btn-switch {
  margin-left: 1.25rem;
  padding: 0.5rem 1.4rem;
}
.switch {
  margin-left: 0.8rem;
}

@media (max-width: 450px) {
  .connect-wallet {
    margin-left: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .btn-switch {
    width: 3.6rem;
  }
}
@media (max-width: 350px) {
  .connect-wallet .btn-image {
    display: none;
  }
  .connect-wallet .btn-label {
    margin-left: 0px;
  }
  .connect-wallet {
    height: 3.6rem;
  }
}
