
.shadow-inner {
  --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, .05);
    --tw-shadow-colored: inset 0 2px 4px 0 var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),var(--tw-ring-shadow, 0 0 #0000),var(--tw-shadow);
}
.buy-input .InputSection-static-input {
  font-size: 2.325rem;
}

.buy-input.Exchange-swap-section {
  min-height: 9.2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: var(--cardBackgroundColor);
}

.buy-input .TokenSelector-box {
  display: flex;
  align-items: center;
  border-radius: 0.4rem;
  /* padding: 0.5rem 0; */
  /* color: #1a2b6b; */
  min-width: 4.65rem;
  font-size: 1.9rem;
  line-height: 2.5rem;
  justify-content: flex-end;
}
.buy-input .TokenSelector-box:hover {
  /* color: #7885ff; */
}

.buy-input .Exchange-swap-input-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* .buy-input .Exchange-swap-max {
  position: unset;
  color: white;
  font-size: 1.5rem;
} */

.buy-input .TokenSelector-caret {
  margin-left: 5px;
  margin-right: -0.5rem;
}

.buy-input .TokenSelector-box-symbol {
  margin-left: 0.8rem;
}

.buy-input .PositionEditor-token-symbol {
  display: flex;
  align-items: center;
  margin-left: 1.4rem;
}

.buy-input .PositionEditor-token-symbol img {
  margin-left: 0.8rem;
  display: none;
}

.buy-input .Exchange-swap-section-top {
  padding-bottom: 0;
  font-size: 1.4rem;
  font-weight: 400;
}

.buy-input .selected-token {
  /* padding-top: 0.5rem; */
  /* padding-bottom: 0.5rem; */
  display: flex;
  align-items: center;
}

.buy-input .Exchange-swap-balance {
  color: #6d7380;
}
