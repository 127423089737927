.Tab.block {
  display: flex;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 15px;
  overflow: hidden;
  color: var(--textColor);
}

.Tab-option-icon {
  margin-right: 0.465rem;
  opacity: 0.7;
}

.Tab.block .Tab-option {
  text-align: center;
  padding: 0.8rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  cursor: pointer;
  border: 2px solid var(--footerBackgroundColor);
  border-width: 0;
  font-weight: 700;
}
.Tab.block .Tab-option:first-child {
  border-width: 0;
}
.Tab.block .Tab-option:last-child {
  border-width: 0;
}
.Tab.block .Tab-option:hover {
  color: #375bd290;
  opacity: 1;
}
.Tab.block .Tab-option.active img {
  filter: brightness(1) invert(0);
}
.Tab.block .Tab-option.active {
  opacity: 1;
  pointer-events: none;
  color: var(--textColor);
  font-weight: 700;
}

.Tab.block .Tab-option.active .Tab-option-icon {
  opacity: 1;
}

.Tab.inline .Tab-option {
  cursor: pointer;
  display: inline-block;
  margin-right: 1.5rem;
  font-size: 1.5rem;
  color: #657290;
}

.Tab.inline .Tab-option:hover {
  opacity: 0.8;
}

.Tab.inline .Tab-option.active {
  opacity: 1;
  pointer-events: none;
  color: #375bd2;
}

@media (max-width: 450px) {
  .Tab.block .Tab-option:hover {
    background: var(--footerBackgroundColor);
  }
}
