html,
body,
#root {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  color: white;
  font-size: 10px;
  background-color: #f9f9f9;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: subpixel-antialiased;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* color */
  --backgroundColor: #f8fafc;
  --textColor: #1a2b6b;
  --textColor2: #1a2b6b;
  --textColor3: #657290;
  --textColor4: #dfe7fb;
  --textColor5: #375bd2;
  --textColor6: #1a2b6b;
  --textColor7: #1a2b6b;
  --textColor8: #375bd2;
  --textColor9: #f1f2f2;
  --textColor10: #1a2b6b;
  --textColor11: #dfe7fb;
  --textColor12: #b5b7bf;
  --textColor13: #3d4556;
  --textColor14: #f9f9f9;
  --textColor15: #1a2b6b;
  --textColor16: #ffffff;
  --textColor100: #0f1943;
  --textColor20: #1a2b6b;
  --textHeaderActive: #000000;
  --textHeaderInactive: rgba(0, 0, 0, 0.6);
  --cardHeaderBackgroundColor: #ffffff;
  --cardHeaderBackgroundColor2: #dfe7fbb2;
  --cardTitleBackgroundColor: #ffffff;
  --cardBackgroundColor: #ffffff;
  --footerBackgroundColor: #ffffff;
  --buttonLightBackground: #dfe7fb;
  --buttonDarkBackground: #375bd2;
  --buttonDarkBackground2: #375bd2;
  --buttonDarkBackground3: #375bd2;
  --secondaryButtonBackground: rgba(243, 243, 243, 1);
  --secondaryButtonTextColor: #000;
  --dividerColor: #adbdef;
  --connectWalletDividerColor: rgba(193, 193, 193, 0.3);
  --tooltipBackgroundColor: #eeeeee;
  --logoUrl: url("/src/img/logo_GMX.svg");
  --logoOAP: url("/src/img/logo_oap.svg");
  --chartHeaderBackgroundColor: #dfe7fbb2;
  --boxBackgroundColor2: #ffffff;
  --boxBackgroundColor: #ffffff;
  --maxButtonColor: linear-gradient(to right top, #375bd5, #3062db, #2869e0, #1f70e6, #1277eb);
  --inputColor: #6d7380;
  --dividerColor: rgba(109, 115, 128, 0.5);
  --sliderColor: #bdc9e7;
  --tabInactiveColor: #375bd280;
  --tabActiveColor: #375bd2;
  --chartBackgroundColor: #edf2fe;
  --referralBoxBackgroundColor: #375bd2;
  --modalContentBackground: #f8fafc;
  --modalButtonBackground: #f8f8f8;
  --modalButtonText: #000000;
  --modalHeaderBackground: #f8fafc;
  --modalLinkText: #375bd2;
  --appHeaderBackground: #fff;
  --footerSocialIconColor: #000;
  --svgSocialColor: brightness(1) invert(0);
  --swapBallBgColor: rgb(248 250 252);
  --swapBallColor: #375bd2;
  --primaryButtonBackground: linear-gradient(to right top, #375bd5, #3062db, #2869e0, #1f70e6, #1277eb);
  --newEthSectionBackground: linear-gradient(180deg, #fff 0%, #e0e9ff 100%);
  --exchangeSwapSectionBorder: 1px solid #ebf0ff;
  --borderColor: #dfe7fb;
  --listTabBackground: #fff;
  --listTabColumnHeaderColor: #657290;
  --settingPopupBackgroundColor: #ffffff;
}
body.dark-theme {
  /* color */
  --backgroundColor: linear-gradient(180deg, #0a112b 0%, rgba(6, 13, 37, 1) 100%);
  --textColor: #ffffff;
  --textColor2: #ffffff;
  --textColor3: #aab5d1;
  --textColor4: #1a2b6b;
  --textColor5: #dfe7fb;
  --textColor6: #ffffff;
  --textColor7: #ffffff;
  --textColor8: #aab5d1;
  --textColor9: #f1f2f2;
  --textColor10: #ffffff;
  --textColor11: rgba(255, 255, 255, 0.1);
  --textColor12: #b5b7bf;
  --textColor13: #3d4556;
  --textColor14: #375bd2;
  --textColor15: #375bd2;
  --textColor16: #375bd2;
  --textColor20: #657290;
  --textColor100: #dfe7fb;
  --textHeaderActive: #ffffff;
  --textHeaderInactive: #ffffff80;
  --cardHeaderBackgroundColor: rgba(134, 134, 134, 0.12);
  --cardHeaderBackgroundColor2: #04091c80;
  --cardTitleBackgroundColor: rgba(134, 134, 134, 0);
  --cardBackgroundColor: rgba(134, 134, 134, 0.12);
  --footerBackgroundColor: #04091e;
  --buttonLightBackground: #1a2b6b;
  --buttonDarkBackground: #375bd2;
  --buttonDarkBackground2: #0f1943;
  --buttonDarkBackground3: #1a2b6b;
  --secondaryButtonBackground: rgba(32, 39, 62, 1);
  --secondaryButtonTextColor: #ffffff;
  --dividerColor: #0f1a44;
  --tooltipBackgroundColor: #202020;
  --logoUrl: url("/src/img/logo_open_white.svg");
  --logoOAP: url("/src/img/logo_oap_white.svg");
  --chartHeaderBackgroundColor: #04091c;
  --boxBackgroundColor2: #04091c;
  --boxBackgroundColor: #070e27;
  --maxButtonColor: linear-gradient(to right top, #375bd5, #3062db, #2869e0, #1f70e6, #1277eb);
  --inputColor: #657290;
  --dividerColor: rgba(255, 255, 255, 0.1);
  --connectWalletDividerColor: #0d1530;
  --sliderColor: #070e27;
  --tabInactiveColor: #1a2b6b80;
  --tabActiveColor: #1a2b6b;
  --chartBackgroundColor: #04091c;
  --referralBoxBackgroundColor: #0f1943;
  --modalContentBackground: #060d25;
  --modalButtonBackground: #8686861f;
  --modalButtonText: #ffffff;
  --modalHeaderBackground: #060d25;
  --modalLinkText: #90bdff;
  --appHeaderBackground: rgba(4, 9, 30, 1);
  --footerSocialIconColor: #fff;
  --svgSocialColor: brightness(0) invert(1);
  --swapBallBgColor: #375bd2;
  --swapBallColor: rgb(248 250 252);
  --primaryButtonBackground: linear-gradient(to right top, #375bd5, #3062db, #2869e0, #1f70e6, #1277eb);
  --newEthSectionBackground: #8686861f;
  --exchangeSwapSectionBorder: 1px solid rgba(235, 240, 255, 0.1);
  --borderColor: rgba(223, 231, 251, 0.1);
  --listTabBackground: #8686861f;
  --listTabColumnHeaderColor: #fff;
  --settingPopupBackgroundColor: rgb(23, 25, 48);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

* {
  box-sizing: border-box;
  font-family: "Inter", "DM Sans", "Montserrat", sans-serif;
  /* letter-spacing: 0.5px; */
  /* font-variant-numeric: tabular-nums; */
}

input {
  font-family: "Inter";
}

::-webkit-scrollbar {
  /* width: 10px; */
}

/* Track */
::-webkit-scrollbar-track {
  background: #1c1c1c;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #383838;
  border-radius: 1.5rem;
  border: 1px solid #212121;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #4a4a4a;
}

:root {
  --dark-blue: #17182c;
  --dark-blue-bg: #16182e;
  --dark-blue-accent: #a0a3c4;
  --dark-blue-hover: #808aff14;
  --dark-blue-border: #ffffff29;
  --dark-blue-active: #818cef33;
  --avax-red: #e84143;
  --arbitrum-blue: #28a0f0;
  --border-radius-sm: 0.4rem;
  --error-red: #e8636b;
}

a {
  color: var(--textColor);
}
a:hover {
  color: #375bd2;
}

.Card-title {
  font-size: 3.1rem;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

input {
  font-size: 2rem;
  color: var(--inputColor);
  outline: none;
  background: none;
  border: none;
  padding: 1.25rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

input::placeholder {
  color: var(--inputColor);
  opacity: 0.6;
}

button {
  outline: none;
  cursor: pointer;
}

button:disabled,
button.App-cta:disabled {
  cursor: not-allowed;
  opacity: 0.9;
  box-shadow: none;
}

table {
  margin: 0;
  padding: 0;
}

tr {
  margin: 0;
  padding: 0;
}

td {
  margin: 0;
  padding: 0;
  padding-right: 0.465rem;
}

.border {
  border: 1px solid rgba(163, 165, 196, 0.2);
}

.align-right {
  text-align: right;
  display: flex;
  justify-content: flex-end;
}

.muted {
  opacity: 0.7;
}

.strong {
  font-weight: bold;
}

.clickable {
  cursor: pointer;
}

.underline {
  text-decoration: underline;
}

.text-none {
  text-transform: none;
}

.relative {
  position: relative;
}

.margin-bottom {
  margin-bottom: 0.465rem;
}

.transition-arrow {
  vertical-align: middle;
  margin-top: -0.31rem;
}

.inline-block {
  display: inline-block;
}

.text-white {
  color: white;
}

.label,
.label a {
  color: rgba(255, 255, 255, 0.7);
}

ul {
  padding-left: 2.325rem;
  margin-bottom: 0;
}

ul li {
  padding-bottom: 1.5rem;
}

a.plain {
  text-decoration: none;
}

.checkbox:checked:before {
  background-color: green;
}

.default-btn {
  background: var(--buttonDarkBackground);
  border-radius: 4px;
  font-size: 1.4rem;
  line-height: 17px;
  font-weight: 700;
  letter-spacing: 0px;
  color: var(--textColor9) !important;
  padding-left: 1.6rem;
  padding-right: 1.6rem;
  /* padding: 9px 16px!important; */
  text-decoration: none;
  box-sizing: border-box;
  position: relative;
  min-height: 3.6rem;
  display: inline-flex !important;
  align-items: center;
  text-align: center;
  cursor: pointer;
  border: none;
  appearance: none;
  border-radius: 0.5rem;
}

.default-btn-light {
  color: var(--textColor8);
  border-radius: 4px;
  font-size: 14px;
  line-height: 17px;
  font-weight: 700;
  font-weight: 700;
  letter-spacing: 0px;
  padding-left: 1.6rem;
  padding-right: 1.6rem;
  text-decoration: none;
  box-sizing: border-box;
  position: relative;
  min-height: 3.6rem;
  display: inline-flex !important;
  align-items: center;
  text-align: center;
  cursor: pointer;
  border: none;
  appearance: none;
  border-radius: 18px;
  border: 3px solid var(--textColor8);
}

/* .default-btn:hover {
  background: #DFE7FB;
} */

/* .default-btn:focus {
  background: #515ed8;
  border: 1px solid #3D4FFE;
  box-sizing: border-box;
  padding-left: 15px;
  padding-right: 15px;
} */

.default-btn:active {
  /* background: #5f6ffd; */
  border: none;
  padding-left: 1.6rem;
  padding-right: 1.6rem;
  box-sizing: border-box;
  overflow: hidden;
}

.default-btn:active::after {
  opacity: 0.239;
  content: " ";
  width: 5.2rem;
  height: 5.2rem;
  position: absolute;
  left: calc(50% - 2.6rem);
  top: -0.8rem;
  background: white;
  border-radius: 50%;
  transform: scale(0);
  animation: ripple 600ms linear;
}

.default-btn:disabled {
  background: #2d2e3f 0% 0% no-repeat padding-box;
  color: #ffffff61;
}

.default-container {
  max-width: 126.4rem;
  padding-left: 3.2rem;
  padding-right: 3.2rem;
  margin: 0 auto;
}

.secondary-btn {
  background: #03d1cf;
  border-radius: 4px;
  font-size: 1.4rem;
  line-height: 2rem;
  font-weight: normal;
  letter-spacing: 0px;
  color: black;
  padding-left: 1.6rem;
  padding-right: 1.6rem;
  /* padding: 9px 16px!important; */
  text-decoration: none;
  box-sizing: border-box;
  position: relative;
  min-height: 3.6rem;
  border: none;
  display: inline-flex !important;
  align-items: center;
  text-align: center;
}

.secondary-btn:hover {
  background: #3fdad9;
}

.secondary-btn:focus {
  background: #54dedd;
  border: 1px solid #1cb5b4fc;
  box-sizing: border-box;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.secondary-btn:active {
  background: #54dedd;
  border: none;
  box-sizing: border-box;
  overflow: hidden;
  padding-left: 1.6rem;
  padding-right: 1.6rem;
  /* padding: 9px 16px; */
}

.secondary-btn:active::after {
  opacity: 0.239;
  content: " ";
  width: 5.2rem;
  height: 5.2rem;
  position: absolute;
  left: calc(50% - 2.6rem);
  top: -0.8rem;
  background: white;
  border-radius: 50%;
  transform: scale(0);
  animation: ripple 500ms linear;
}

.secondary-btn:disabled {
  background: #2d2e3f 0% 0% no-repeat padding-box;
  color: #ffffff61;
}

@keyframes ripple {
  to {
    transform: scale(2);
    opacity: 0.4;
  }
}

button.btn-link {
  margin-top: 1.5rem;
  background: none;
  color: white;
  border: none;
  padding: 0;
  font-size: 1.5rem;
  display: block;
  text-align: left;
}

.logout-icon {
  vertical-align: middle;
  font-size: 1.7rem;
  margin-top: -0.31rem;
  margin-right: 0.5rem;
  margin-left: -0.465rem;
  opacity: 0.8;
}

button.btn-link:hover {
  text-decoration: underline;
}

button.btn-link:hover .logout-icon {
  opacity: 1;
}

.page-container {
  padding-top: 6.2rem;
}

.default-sm-container {
  max-width: 108.8rem;
}

.mt-0 {
  margin-top: 0 !important;
}

.page-layout {
  min-height: calc(100vh - 62px);
  display: flex;
  flex-direction: column;
  padding-bottom: calc(90px + 20rem);
  padding-top: 80px;
}

.d-flex {
  display: flex;
}

.justify-space-between {
  justify-content: space-between;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-column {
  flex-direction: column;
}

.w-100 {
  width: 100%;
}

.font-sm {
  font-size: 1.4rem;
}
.font-base {
  font-size: 1.5rem;
}
.mr-base {
  margin-right: 1.5rem;
}
.default-cursor.App-cta {
  cursor: default;
  background-color: #03d1cf;
}
