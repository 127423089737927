.GlpSwap-content {
  display: flex;
  justify-content: space-between;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  justify-content: center;
}

.App-card .App-card-divider-2 {
  margin: 0;
  height: 1px;
  background-color: var(--dividerColor);
}

.Tab-title {
  letter-spacing: 0px;
  color: #ffffff;
  font-size: 3.41rem;
  font-weight: 500;
}

.Tab-description {
  letter-spacing: 0px;
  color: #a9a9b0;
  margin-top: 0.8rem;
}

.Tab-description span {
  color: #f89191;
}

.GlpSwap-from-token .Modal {
  align-items: flex-start;
  justify-content: flex-end;
}

.GlpSwap-from-token .Modal .Modal-content {
  width: 41.85rem;
}

.GlpSwap-from-token .Modal-content {
  position: absolute;
  width: 38.75rem;
  max-height: 100vh;
  overflow: hidden;
  border: none;
  top: 0;
  bottom: 0;
}
.GlpSwap-from-token .Modal-content .Modal-body {
  padding-right: 0.5rem;
  max-height: calc(100vh - 7.75rem);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  -webkit-overflow-scrolling: auto;
}

.GlpSwap-content .GlpSwap-stats-card,
.GlpSwap-content .GlpSwap-box {
  width: 100%;
  background: var(--cardBackgroundColor);
}

.GlpSwap-content .GlpSwap-stats-card {
  max-width: 71.2rem;
  margin-right: 1.5rem;
}

.GlpSwap-stats-card .App-card-row .label {
  color: #6d7380;
  opacity: 1;
  font-weight: bold;
}

.GlpSwap-content .GlpSwap-box {
  max-width: 60rem;
  border-radius: 8px;
  padding: 1.5rem;
  padding-top: 0;

  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.05);
}

.GlpSwap-box .Exchange-swap-option-tabs {
  margin: 1.5rem 0;
  border-radius: 18px;
  font-size: 2rem;
  flex-wrap: wrap;
  gap: 4px;
}

.GlpSwap-box .Exchange-swap-option-tabs.Tab.block .Tab-option {
  flex: none;
}

.Exchange-swap-option-tabs.Tab.block .Tab-option.Buy.active {
  color: #01a789;
}

.Exchange-swap-option-tabs.Tab.block .Tab-option.Sell.active {
  color: #e8636b;
}

.App-card-title-mark-icon {
  position: relative;
  margin-right: 0.8rem;
  display: flex;
  width: 3.1rem;
  height: 3.1rem;
}

.App-card-title-mark-icon.oap-logo img {
  content: var(--logoOAP);
}

.App-card-title-mark-icon.open-logo img {
  content: var(--logoUrl);
}

.App-card-title-mark-icon .selected-network-symbol {
  position: absolute;
  bottom: 0;
  right: 0;
  border: 1px solid #1e1f37;
  border-radius: 50%;
}

.App-card-title-mark {
  display: flex;
}

.App-card-title-mark-info {
  margin: auto;
  margin-left: 0.8rem;
}

.App-card-title-mark .dropdown-arrow,
.App-card-title-mark .App-card-title-mark-title {
  font-size: 1.3rem;
  letter-spacing: 0px;
  color: var(--textColor);
  font-weight: 700;
}

.App-card-title-mark .App-card-title-mark-subtitle {
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  color: var(--textColor);
}

.GlpSwap-token-list {
  border-radius: 20px;
  text-align: left;
}

.GlpSwap-token-list-content {
  display: grid;
  padding: 1.5rem;
  padding-top: 0;
  text-align: left;
}

.GlpSwap-box .App-card-title {
  margin-bottom: 0.8rem;
}

.GlpSwap-box .Exchange-info-row {
  height: 1.5rem;
  margin-bottom: 0.8rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.GlpSwap-box .Exchange-info-row .Exchange-info-label {
  margin-right: 0.8rem;
  color: #a9a9b0;
  opacity: 1;
}

.GlpSwap-box .Tab-option {
  color: var(--textColor);
}

.GlpSwap-cta {
  margin: 0 auto;
  text-align: center;
  padding-top: 0;
}

.GlpSwap-cta .Exchange-swap-button {
  margin: 0 auto;
  justify-content: center;
  min-height: 4.8rem;
  font-size: 1.5rem;
}

.token-table {
  width: calc(100%);
  border-spacing: 0;
  white-space: nowrap;
  background: var(--cardBackgroundColor);
}
.token-table.buy {
  border-radius: 20px;
}

.token-grid {
  display: none;
}
.token-grid-container {
  display: none;
}
.token-grid-container .App-card-title img,
.token-table-wrapper .App-card-title img {
  filter: brightness(0) invert(1);
}

.token-table td,
.token-table th {
  padding: 1.1625rem;
}

.token-table td:nth-child(2),
.token-table th:nth-child(2) {
  width: 11rem;
}

.token-table td:nth-child(3),
.token-table th:nth-child(3) {
  width: 32rem;
}

.token-table td:nth-child(4),
.token-table th:nth-child(4) {
  width: 20rem;
}

.token-table td:nth-child(5),
.token-table th:nth-child(5) {
  width: 13rem;
}

.token-table td {
  letter-spacing: 0.25px;
  color: var(--textColor2);
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}

.token-table th:first-child,
.token-table td:first-child {
  padding-left: 20px;
  width: 26.35rem;
}

.token-table th:last-child,
.token-table td:last-child {
  padding-right: 1.5rem;
  text-align: right;
  width: 17rem;
}

.token-table th {
  font-weight: 800;
  font-size: 1.4rem;
  line-height: 17px;
  color: var(--textColor3);
}

.token-table .action-btn {
  width: 100%;
  justify-content: center;
}
.cap-reached {
  margin-left: 0.8rem;
}

.AppOrder-ball-container {
  position: relative;
  z-index: 2;
}

.AppOrder-ball-icon {
  text-align: center;
  display: block;
  font-size: 2.325rem;
  opacity: 0.7;
}

.AppOrder-ball {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 3.565rem;
  height: 3.565rem;
  left: 50%;
  margin-left: -1.7825rem;
  top: -2.2rem;
  border-radius: 3.1rem;
  user-select: none;
  background: #375bd2;
  cursor: pointer;
  opacity: 0.85;
}
.AppOrder-ball:hover {
  opacity: 1;
}
.StakeV2-cards .App-card-content,
.GlpSwap-token-list .App-card-content {
  padding-bottom: 0.25rem !important;
}

@media (max-width: 900px) {
  .GlpSwap-content {
    flex-wrap: wrap;
  }

  .GlpSwap-content .GlpSwap-stats-card {
    margin: 0;
    margin-bottom: 3.1rem;
    max-width: 100%;
  }

  .GlpSwap-content .GlpSwap-box {
    margin: 0;
    max-width: 100%;
  }
}

@media (max-width: 1100px) {
  .GlpSwap-from-token .Modal {
    align-items: center;
    justify-content: center;
  }
  .GlpSwap-from-token .Modal .Modal-content {
    width: 41.85rem;
    position: absolute;
    max-height: 80vh;
    overflow: hidden;
    border: none;
    top: auto;
    bottom: auto;
  }
  .GlpSwap-from-token .Modal .Modal-content .Modal-body {
    overflow-y: auto;
    max-height: calc(80vh - 8.525rem);
    padding-right: 5px;
    margin: 1.5rem calc(1rem - 0.5rem) 1.5rem 1.5rem;
  }

  .GlpSwap-token-list {
    border: none;
    background-color: transparent;
    padding: 0;
  }

  .GlpSwap-token-list-content {
    padding: 0;
  }

  .token-table {
    display: none;
  }

  .token-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1.5rem;
    margin: 20px;
  }
  .token-grid-container {
    display: flex;
    flex-direction: column;
    background: var(--boxBackgroundColor2);
    border-radius: 20px;
  }
  .token-grid-container .App-card-title {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    justify-content: flex-start;
  }
  .token-grid-container .token-grid .App-card-title,
  .token-grid-container .token-grid .App-card-content {
    background: var(--boxBackgroundColor);
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
  }
  .token-grid-container .token-grid .App-card-content {
    padding-top: 0;
  }
  .token-grid-container .token-grid .token-symbol-text {
    color: var(--textColor2);
  }
  .token-grid-container .token-grid .dropdown-arrow {
    color: var(--textColor2) !important;
  }
  .token-grid-container .App-card-title img {
    margin-left: 10px;
  }
}
@media (max-width: 510px) {
  .token-grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1.5rem;
  }
  .token-grid-container .App-card-title img {
    display: none;
  }
}
