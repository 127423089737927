.menu-items:focus-visible {
  border: 1px solid #262638;
}
.address-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.user-address {
  margin-left: 1rem;
  margin-right: 1rem;
  color: var(--textColor9) !important;
}

.App-header-user-address:hover {
  background: #808aff14;
}

.menu-items {
  position: absolute;
  right: 0;
  top: 4.3rem;
  min-width: 15.5rem;
  width: 100%;
  transform-origin: top right;
  border-radius: 0.4rem;
  color: var(--textColor7);
  background: var(--tooltipBackgroundColor);
  list-style: none;
  cursor: pointer;
  outline: none;
  z-index: 1000;
  box-shadow: 0px 7px 30px -19px rgba(0,0,0,0.75) !important;
  -webkit-box-shadow: 0px 7px 30px -19px rgba(0,0,0,0.75) !important;
  -moz-box-shadow: 0px 7px 30px -19px rgba(0,0,0,0.75) !important;
}
.menu-item {
  display: flex !important;
  align-items: center;
  font-size: 1.5rem;
  color: var(--textColor7);
  padding-bottom: 1.5rem;
  font-size: 1.4rem;
  padding: 0.85rem 0.8rem;
  font-weight: 500;
}

.menu-item:hover {
  opacity: 1;
}
.menu-item > p {
  margin: 0px;
  padding-left: 1rem;
}
.menu-item > a {
  display: inline-flex;
}

@media screen and (max-width: 370px) {
  .user-address {
    margin-left: 0;
  }
  .address-btn {
    display: flex;
    justify-content: space-between;
  }
  .user-avatar {
    display: none;
  }
}
