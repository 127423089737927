.Modal {
  position: fixed;
  z-index: 900;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;
}
.Modal-scrollable .Modal-content .Modal-body {
  overscroll-behavior: smooth;
  max-height: calc(80vh - 5.5rem);
  overflow-y: auto;
  padding-right: 5px;
}

.Modal-backdrop {
  position: fixed;
  z-index: 10;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.9);
}


.Modal-content {
  z-index: 20;
  position: relative;
  max-width: 90%;
  max-height: 90vh;
  overflow: auto;
  background: var(--modalContentBackground);
  border-radius: 4px;


}

.divider {
  border-bottom: 1px solid #ffffff29;
  margin-bottom: 1.5rem;
  background-color: var(--inputColor);
}

.Modal.non-scrollable .Modal-content {
  overflow: visible;
}

.Modal-title-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem;
  background: var(--modalHeaderBackground);
}
.Modal-body {
  margin: 1.5rem;
  color: var(--textColor6);
}
.CheckBox .Checkbox-icon-wrapper .Checkbox-icon.inactive {
  margin: 1.5rem;
  color: #375bd2;
}
.Modal-body::-webkit-scrollbar {
  width: 0.6rem;
}

.Modal-body::-webkit-scrollbar-track {
  background-color: #1c1c1c;
  border-radius: 155rem;
}

.Modal-body::-webkit-scrollbar-thumb {
  background-color: #949393;
  border-radius: 155rem;
}

.Modal-title {
  text-align: left;
  font-size: 1.5rem;
  line-height: 1;
  color: var(--textColor6);
  font-weight: 700;
}

.Modal-close-button {
  text-align: right;
}

.Modal-close-icon {
  opacity: 0.6;
  cursor: pointer;
  text-align: right;
  display: inline-block;
  color: var(--textColor6);
}

.Modal-close-icon:hover {
  opacity: 0.9;
}

.Modal-note {
  margin-bottom: 1.5rem;
  margin-top: 0.8rem;
}
